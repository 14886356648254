import React from "react";

const Header = props => (
  <div className="header">
    <h3>You're almost done!</h3>
    <p className="text-justify">
      But there is still <strong>one more step</strong> to be followed.
    </p>
    <p className="text-justify">
      To proceed with the confirmation of your booking, please read the
      information below and then proceed with the payment by clicking on the
      "Pay Now” button.
    </p>
    <p className="text-justify">
      Upon clicking on "Pay Now", your consultation will be temporarily
      scheduled for a maximum period of 24 hours. Please note that, should you
      fail to make such payment within this period, your booking will be
      automatically cancelled and you will have to book it once again (subject
      to new availability).
    </p>
    <p className="text-justify">
      Please also note that you should receive an email with a final
      confirmation of your booking once we have successfully confirmed your
      payment.
    </p>
    <p className="text-justify">
      If you do not receive this email within 24 hours of making your payment,
      please contact{" "}
      <a href="mailto:info@sevenmigration.com.au">info@sevenmigration.com.au</a>
      .
    </p>
  </div>
);

export default Header;
