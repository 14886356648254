import React from "react";
import Form from "react-bootstrap/Form";

const CustomDropdown = props => (
  <Form.Group controlId={props.Label}>
    <Form.Label>{props.label}:</Form.Label>
    <Form.Control
      as="select"
      onChange={props.onChange}
      className="office-dropdown"
    >
      {!props.selectedOffice.id && (
        <option hidden selected disabled>
          Click here to select an office...
        </option>
      )}
      {props.options}
    </Form.Control>
  </Form.Group>
);

export default CustomDropdown;
