import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const CheckboxColumn = props =>
  props.visas.map(visa => (
    <Form.Check
      custom
      checked={props.client.visa_ids.indexOf(visa.id) !== -1}
      type="checkbox"
      key={visa.id}
      id={visa.id}
      label={visa.name}
      onChange={props.onVisaChange}
    />
  ));

const VisaCheckboxes = props => {
  const visas = props.visas;
  const sliceSize = Math.round(visas.length / 2);
  const firstColumnVisas = visas.slice(0, sliceSize);
  const secondColumnVisas = visas.slice(sliceSize);
  const content = (
    <Col lg="6">
      <Form.Label>Please select from the following options, if you wish to talk about any specific visa(s) during your consultation:</Form.Label>
      <Row>
        <Col lg="6">
          <CheckboxColumn visas={firstColumnVisas} client={props.client} onVisaChange={props.onVisaChange} />
        </Col>
        <Col lg="6">
          <CheckboxColumn visas={secondColumnVisas} client={props.client} onVisaChange={props.onVisaChange} />
        </Col>
      </Row>
    </Col>
  );

  return content;
};

export default VisaCheckboxes;
