import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { fetchClient, fetchLanguages, fetchReferences, fetchVisas, saveClient } from "../actions/actions";
import VisaCheckboxes from "./step_1/visa_checkboxes";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const buildAboutUsOptions = references => {
  let options = references.map(reference => (
    <option value={reference.id} key={reference.id}>
      {reference.name}
    </option>
  ));
  options.unshift(
    <option hidden value="" key={-1}>
      Please select one of the options below:
    </option>
  );

  return options;
};

const buildPreferredLanguageOptions = languages => {
  let options = languages.map(lang => (
    <option value={lang.id} key={lang.id}>
      {lang.name}
    </option>
  ));
  options.unshift(
    <option hidden value="" key={-1}>
      Please select your preferred language:
    </option>
  );

  return options;
};

const BookingStep1 = props => {
  const serviceId = document.getElementById("service_id").value;
  const isMigrationService = serviceId == "1";

  const [languages, setLanguages] = props.state("languages");
  const [references, setReferences] = props.state("references");
  const [visas, setVisas] = props.state("visas");
  const [client, setClient] = props.state("client");
  const [validated, setValidated] = React.useState(false);

  useEffect(() => {
    fetchLanguages().then(data => setLanguages(data));
    fetchReferences().then(data => setReferences(data));
    fetchVisas().then(data => setVisas(data));
  }, []);

  const onVisaChange = e => {
    const visaId = parseInt(e.target.id);
    const selectedVisas = client.visa_ids;

    if (e.target.checked) {
      selectedVisas.push(visaId);
    } else {
      selectedVisas.splice(selectedVisas.indexOf(visaId), 1);
    }

    setClient({ ...client, visa_ids: selectedVisas });
  };

  const onEmailBlur = () => {
    fetchClient(event.target.value).then(data => {
      if (data.id) {
        setClient(data);
        document.getElementById("fullName").focus();
      } else {
        setClient({
          ...client,
          id: "",
          confirmEmail: "",
          name: "",
          phone: "",
          reference_id: -1,
          language_id: -1,
          engagement_id: 1,
          video_details: "",
          visa_ids: []
        });
        document.getElementById("confirmEmail").focus();
      }
    });
  };

  const onConfirmEmailBlur = () => {
    if (!client.id && client.confirmEmail.length > 0 && client.email !== client.confirmEmail) {
      alert("Email doesn't match with confirm email.");
      setClient({ ...client, confirmEmail: "" });
      document.getElementById("confirmEmail").focus();
    }
  };

  const handleFormSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === true) {
      saveClient(client).then(data => {
        setClient(data);
        props.nextStep();
      });
    }

    setValidated(true);
  };

  const content = (
    <div className="step-1">
      <p className="text-left">Please fill out the information below to schedule your appointment with Seven Migration.</p>
      <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
        <Form.Group as={Row}>
          <Col lg="4">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              id="email"
              required
              aria-describedby="emailHelp"
              placeholder="Eg.: john.doe@example.com"
              value={client.email}
              onChange={() => setClient({ ...client, email: event.target.value })}
              onBlur={onEmailBlur}
              onPaste={() => event.preventDefault()}
            />
            {!client.id && (
              <>
                <Form.Label>Confirm Email</Form.Label>
                <Form.Control
                  id="confirmEmail"
                  type="email"
                  required
                  aria-describedby="emailHelp"
                  value={client.confirmEmail}
                  onChange={() => setClient({ ...client, confirmEmail: event.target.value })}
                  onBlur={onConfirmEmailBlur}
                  onPaste={() => event.preventDefault()}
                />
              </>
            )}
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </Col>
          <Col lg="6">
            <p className="text-justify">
              If you are a returning customer, please, enter your email and will load your persornal information. If something changed, please update
              so we keep your latest details.
            </p>
          </Col>
        </Form.Group>
        <hr />
        <h3>Personal Details</h3>
        <Form.Group as={Row} controlId="fullName">
          <Col lg="6">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              required
              placeholder="Eg.: John Doe"
              value={client.name}
              onChange={() => setClient({ ...client, name: event.target.value })}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="phoneNumber">
          <Col lg="6">
            <Form.Label>Phone Number</Form.Label>
            <PhoneInput
              inputProps={{
                id: "phoneNumber",
                required: true,
                pattern: ".{10,}",
                style: { width: "100%", fontSize: "1rem", heith: "auto" }
              }}
              copyNumbersOnly
              country={"au"}
              value={client.phone}
              onChange={() => setClient({ ...client, phone: event.target.value })}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="aboutUs">
          <Col lg="6">
            <Form.Label>How did you hear about us?</Form.Label>
            <Form.Control
              required
              as="select"
              value={client.reference_id}
              onChange={() => setClient({ ...client, reference_id: event.target.value })}
            >
              {buildAboutUsOptions(references)}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="preferredLanguage">
          <Col lg="6">
            <Form.Label>Preferred Language</Form.Label>
            <Form.Control required as="select" value={client.language_id} onChange={() => setClient({ ...client, language_id: event.target.value })}>
              {buildPreferredLanguageOptions(languages)}
            </Form.Control>
          </Col>
        </Form.Group>
        {isMigrationService && (
          <Form.Group as={Row} controlId="visas">
            <VisaCheckboxes visas={visas} onVisaChange={onVisaChange} client={client} />
          </Form.Group>
        )}
        <Form.Group as={Row} controlId="next">
          <Button variant="outline-primary custom" type="submit">
            Next
          </Button>
        </Form.Group>
      </Form>
    </div>
  );

  return content;
};

export default BookingStep1;
