export const fetchLanguages = async () => {
  const resp = await fetch("/languages");
  const data = await resp.json();

  return data;
};

export const fetchReferences = async () => {
  const resp = await fetch("/references");
  const data = await resp.json();

  return data;
};

export const fetchVisas = async () => {
  const resp = await fetch("/visas");
  const data = await resp.json();

  return data;
};

export const fetchAgents = async service => {
  const resp = await fetch(`/agents?service_id=${service}`);
  const data = await resp.json();

  return data;
};

export const fetchClient = async email => {
  const resp = await fetch(`/find_clients_by_email?email=${email}`);
  const data = await resp.json();

  return data;
};

export const fetchNextBookingPrice = async (clientId, serviceId) => {
  const resp = await fetch(`/clients/${clientId}/next_booking_price?service_id=${serviceId}`);
  const data = await resp.json();

  return data;
};

export const fetchNextBookingPriceDiscount = async (clientId, serviceId, discount) => {
  const resp = await fetch(`/clients/${clientId}/next_booking_price?service_id=${serviceId}&discount=${discount}`);
  const data = await resp.json();

  return data;
};

export const fetchServiceTypes = async email => {
  const resp = await fetch("/service_types");
  const data = await resp.json();

  return data;
};

export const fetchOffices = async serviceId => {
  const resp = await fetch(`/offices?service_id=${serviceId}`);
  const data = await resp.json();

  return data;
};

export const fetchTimetable = async (client, agent, office, service_type) => {
  const params = `client_email=${client.email}&agent_id=${agent.id}&service_type_id=${service_type.id}&office_id=${office.id}`;
  const resp = await fetch(`/build_available_slots?${params}`);
  const data = await resp.json();

  return data;
};

export const validateVoucher = async (promoCode, client, agent, office, service, serviceType) => {
  const params = `promo_code=${encodeURIComponent(promoCode)}&client_id=${client.id}&agent_id=${agent.id}&office_id=${office.id}&service_id=${service.id}&service_type_id=${serviceType.id}`;
  const resp = await fetch(`/vouchers/validate_voucher?${params}`);
  const data = await resp.json();

  return data;
};

export const saveClient = client => {
  const [verb, url] = client.id ? ["PUT", `/clients/${client.id}`] : ["POST", "/clients"];

  const resp = fetch(url, {
    method: verb,
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ client: client })
  }).then(data => data.json());

  return resp;
};

export const saveBooking = (booking, promoCode) => {
  const [verb, url] = ["POST", "/bookings"];

  const resp = fetch(url, {
    method: verb,
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ booking: booking, promo_code: promoCode })
  }).then(data => data.json());

  return resp;
};

export const rescheduleEvent = (url, params) => {
  const resp = fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(params)
  }).then(data => data.json());

  return resp;
};
