import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import BookingDetails from "./step_3/booking_details";
import Footer from "./step_3/footer";
import Header from "./step_3/header";
import PaidHeader from "./step_3/paid_header";
import MigrationAdviceDetails from "./step_3/migration_advice_details";
import SkillsAssessmentDetails from "./step_3/skills_assessment_details";

const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

const BookingStep3 = (props) => {
  const [booking] = props.state("booking");
  const [client] = props.state("client");

  const chargeBooking = client.engagement_id != 4;

  const currencyCode = booking.service.id === 2 ? "BRL": "AUD";

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const content = (
    <div className="step-3">
      {chargeBooking ? <Header /> : <PaidHeader />}
      <hr />
      <Row>
        <Col lg="5">
          <BookingDetails booking={booking} state={props.state} />
        </Col>
        <Col lg="1">
          <br />
          <hr className="vertical-hr" />
        </Col>
        <Col lg="6">{booking.service.id == 1 ? <MigrationAdviceDetails booking={booking} /> : <SkillsAssessmentDetails booking={booking} />}</Col>
      </Row>
      <br />
      <br />
      <Footer bookingId={booking.id} bookingPrice={booking.price} previousStep={props.previousStep} showPayPalButton={chargeBooking} currencyCode={currencyCode}/>
    </div>
  );

  return content;
};

export default BookingStep3;
