// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from "react";
import ReactDOM from "react-dom";
import Wizard from "components/wizard";
import { createGlobalState } from "react-hooks-global-state";
import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/react'

import "../css/wizard";

const initialState = {
  agents: [{ display_name: "Skills Assessment", languages: [], offices: [] }],
  selectedAgent: { languages: [], offices: [] },
  selectedOffice: {},
  availableSlots: [],
  loadingAvailableSlots: false,
  activeSlot: "",
  languages: [],
  references: [],
  visas: [],
  serviceTypes: [],
  selectedServiceType: { id: 1 },
  client: {
    id: "",
    email: "",
    confirmEmail: "",
    name: "",
    phone: "",
    reference_id: -1,
    language_id: -1,
    engagement_id: 1,
    video_details: "",
    accept_newsletter: false,
    visa_ids: [],
  },
  booking: {
    client: {},
    agent: {},
    office: {},
    service_type: {},
    service: {},
    video_details: "",
    price: 0.0,
  },
  promoCode: "",
};

const { GlobalStateProvider, useGlobalState } = createGlobalState(initialState);

const config = {
  apiKey: 'hbp_8tyQG7z0dVSqLKPUH0LFpkj1RetR5d0vi8aK',
  environment: 'production',
  revision: '1.0'
}

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <ErrorBoundary honeybadger={Honeybadger}>
      <GlobalStateProvider>
        <Wizard state={useGlobalState} />
      </GlobalStateProvider>
    </ErrorBoundary>,
    document.body.appendChild(document.createElement("div"))
  );
});
