import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import TermsAndConditions from "./terms_and_conditions";
import { validateVoucher, fetchNextBookingPriceDiscount } from "../../actions/actions";

const Footer = props => {
  const [show, setShow] = React.useState(false);
  const [client, setClient] = props.state("client");
  const [promoCode, setPromoCode] = props.state("promoCode");
  const [selectedAgent] = props.state("selectedAgent");
  const [selectedOffice] = props.state("selectedOffice");
  const [selectedServiceType] = props.state("selectedServiceType");
  const [booking, setBooking] = props.state("booking");
  const serviceId = document.getElementById("service_id").value;
  const [isPromoCodeValid, setIsPromoCodeValid] = React.useState(false);

  const handleClick = e => {
    e.preventDefault();
    setShow(true);
  };

  const handleVoucherBlur = e => {
    setPromoCode(e.target.value.toUpperCase());
    validateVoucher(promoCode, client, selectedAgent, selectedOffice, serviceId, selectedServiceType).then(data => {
      if (data.discount) {
        fetchNextBookingPriceDiscount(client.id, serviceId, data.discount).then(dataBooking => {
          setBooking({ ...booking, price: dataBooking.price });
          setIsPromoCodeValid(true);
          alert(`Your Promo Code has been accepted, you'll receive ${data.discount}% discount on your booking.`);
        });
      } else {
        alert(`Invalid promo code.`);
      }
    });
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <Row>
        <Col lg={{ offset: 8, span: 8 }}>
          <Form.Label>Promo Code</Form.Label>
          <Form.Group as={Row}>
            <Col lg="6">
              <Form.Control
                id="video-details-selected"
                type="text"
                disabled={isPromoCodeValid}
                isValid={promoCode.length > 3 && isPromoCodeValid}
                value={promoCode}
                onChange={() => setPromoCode(event.target.value)}
                onBlur={handleVoucherBlur}
              ></Form.Control>
              <Form.Control.Feedback>Promo Code accepted!</Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <div style={{ marginTop: "35px" }}>
        <Row>
          <Col lg="12">
            <Form.Check
              custom
              type="checkbox"
              id="agree-newsletter"
              label="I agree to receive newsletter an ocasional special offers from Seven Migration."
              checked={client.accept_newsletter}
              onChange={() => setClient({ ...client, accept_newsletter: event.target.checked })}
            />
          </Col>
          <Col lg="12" style={{ display: "flex" }}>
            <Form.Check
              custom
              type="checkbox"
              required
              id="agree-terms-and-conditions"
              label={
                <span>
                  I read and agree with
                  <a href="#" onClick={e => handleClick(e)}>
                    {" "}
                    Terms and Conditions
                  </a>
                  .
                </span>
              }
            />
          </Col>
        </Row>
        <TermsAndConditions show={show} handleClose={handleClose} />
        <Form.Group as={Row} controlId="next" className="navigation-buttons">
          <Button variant="outline-primary custom back" onClick={props.previousStep} style={{ marginRight: "15px" }}>
            Back
          </Button>
          <Button variant="outline-primary custom" onClick={props.handleNextClick}>
            Next
          </Button>
        </Form.Group>
      </div>
    </>
  );
};

export default Footer;
