import React from "react";
import Modal from "react-bootstrap/Modal";

const TermsAndConditions = props => (
  <Modal
    show={props.show}
    onHide={props.handleClose}
    className="terms-of-service"
    size="lg"
  >
    <Modal.Header closeButton>
      <Modal.Title>Terms and Conditions</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h5>
        Please make sure to carefully read the following terms and conditions
        before agreeing. This is a legally binding agreement and, as such, will
        be kept by us for record purposes.
      </h5>
      <h6>
        By agreeing to the following terms and conditions (
        <strong>“Terms and Conditions”</strong>), you agreed and acknowledge
        that:
      </h6>
      <ol>
        <li>
          You will pay any consultation fees (
          <strong>“Consultation Fees”</strong>) payable to Seven Migration Pty
          Ltd (<strong>“Seven Migration”</strong>) for each Consultation you
          book in accordance with Seven Migration’s Payment Policy or as
          otherwise directed by Seven Migration’s website, regardless of the
          amount payable and/or if extra changes are later applied to such
          Consultation Fees.
        </li>
        <li>
          All Consultation Fees must be paid in full to Seven Migration via its
          secured website prior to consultations (
          <strong>“Consultations”</strong> or <strong>“Appointments”</strong>)
          being scheduled or taking place.
        </li>
        <li>
          Seven Migration reserves the right to refrain you from attending an
          Appointment in case payment has not been made and/or successfully
          approved/confirmed by the due date.
        </li>
        <li>
          All Consultation Fees are calculated on the basis of Seven Migration’s
          Fee Structure and may, from time to time, be altered.
        </li>
        <li>
          Seven Migration reserves the right to make changes to Consultation
          Fees without prior notice; in which case, future bookings may be
          subject to different pricing.
        </li>
        <li>
          Should any of the aforementioned changes/alterations take place, Seven
          Migration will update its website and, whenever you book an
          Appointment, Consultation Fees covering such Consultation will be
          calculated and charged as per the information available on Seven
          Migration’s website at the time of the booking (i.e., updated
          information).
        </li>
        <li>
          Consultation Fees are pre-established financial outlays that are
          non-negotiable.
        </li>
        <li>
          Any amounts displayed on Seven Migration’s website are{" "}
          <strong>inclusive of GST</strong>, whenever applicable.
        </li>
        <li>
          Any amounts displayed on Seven Migration’s website do not include
          other surcharges that might be applicable to the transaction depending
          on your preferred payment method.
        </li>
        <li>
          Seven Migration’s website may provide you with one (or more) payment
          options/methods and, by selecting a particular payment option/method,
          you agree to be bound by any terms and conditions established by the
          provider of such option/method.
        </li>
        <li>
          Bookings are subject to Seven Migration’s date and time availability
          and, accordingly, are subject to change.
        </li>
        <li>
          Seven Migration reserves the right to cancel or reschedule your
          Appointment at any given time from the moment you book it.
        </li>
        <li>
          Should you, for any reason, be required to cancel and/or reschedule
          your Appointment, you will need to provide Seven Migration with, at
          least, forty-eight (48) hours notice.
        </li>
        <li>
          Failure to comply with <strong>item 13</strong> may result in refunds
          not being granted by Seven Migration, if requested.
        </li>
        <li>
          Should you, for any reason, be required to cancel your appointment,
          you will only be entitled to a refund upon sending Seven Migration a
          formal request (via email), and provided that you meet with Seven
          Migration’s Refund Policy (as outlined in items 16 to 24 of these
          Terms and Conditions).{" "}
        </li>
        <li>
          Should, for any reason, Seven Migration be required to cancel or
          reschedule your appointment, you will only be entitled to a refund
          upon sending Seven Seven Migration a formal request (via email).
        </li>
        <li>
          Any refund requests and/or claims you make will be individually
          assessed by Seven Migration.
        </li>
        <li>
          Seven Migration reserves the right not to issue you refunds in case
          you fail to comply with any of the provisions set under these Terms
          and Conditions.
        </li>
        <li>
          Seven Migration will make all reasonable efforts to provide you with a
          full refund of Consultation Fees paid, should your Appointment be
          cancelled and request for such refund be sent to Seven Migration, in
          accordance with <strong>items 13 to 16</strong>.
        </li>
        <li>
          All refunds will be processed within three (3) business days following
          Seven Migration’s approval of your request/claim for a refund.
        </li>
        <li>
          Any refunds given to you will be inclusive of GST (i.e., GST is also
          refundable). Alternatively, any other fees or surcharges paid by you
          to third parties and/or payment providers (such as banks/financial or
          PayPal) are non-refundable.
        </li>
        <li>
          <strong>No refunds</strong> will be made available after your
          Consultation has taken place, whether you have attended to it or not.{" "}
        </li>
        <li>
          In light of <strong>item 22</strong>, should you fail to attend your
          appointment for any reason and fail to cancel or reschedule it in
          accordance with <strong>item 13</strong>, a refund will not be
          likewise made available.
        </li>
        <li>
          In accordance with <strong>item 23</strong>, absences will not be
          subject to discounts, refunds, credit or transfer of dates or times
          under any circumstances. The same applies in case you are late to you
          Consultation.
        </li>
        <li>
          Upon completing the online booking, your Appointment will be
          temporarily scheduled for a period of twenty-four (24) hours - after
          which it will be automatically cancelled, should you fail to make the
          corresponding payment or should Seven Migration be unable to confirm
          your payment within this twenty-four (24) hour grace period. As a
          result, should Seven Migration be unable to verify your payment in
          time, your Appointment will be automatically cancelled and you will
          have to reschedule it, subject to new availability.
        </li>
        <li>
          Upon receiving and successfully confirming your payment, Seven
          Migration will issue a confirmation of your booking. Until such a
          time, Seven Migration shall not be held responsible or liable should
          any other persons book an Appointment on the date and/or time-slot you
          have selected.
        </li>
        <li>
          Seven Migration shall be held free of any liability arising from any
          funds lost as a result of payment issues, changes to Consultation
          times and/or dates, or any other reasons (without any limitation) and
          no other claims for compensation or expense reimbursements by you
          shall be admissible and/or enforceable upon Seven Migration.
        </li>
        <li>
          You will be required to accept any changes to Seven Migration’s Fees
          Structure and to pay any differences to cover discrepancies, whenever
          applicable and/or as otherwise directed by Seven Migration. Please
          note that future changes to Seven Migration’s Fees Structure{" "}
          <strong>will not affect you</strong> once you have paid for your
          Consultation, unless these changes relate to subsequent appointments
          which are to be charged and/or treated as new bookings.
        </li>
        <li>
          In the interest of all parties, Seven Migration reserves the right to
          terminate any Consultation and to enforce the removal of any person
          (or persons) from its premises whose behaviour or demeanour is, in
          Seven Migration’s view, deemed to be unacceptable.
        </li>
        <li>
          Should Seven Migration exercise any of the actions outlined in{" "}
          <strong>item 29</strong>, you shall not be entitled to any discounts,
          reimbursements and/or refunds of monies paid to Seven Migration in
          connection to Consultations, as well as to any form of credit and/or
          transfer of Consultation date and/or time.
        </li>
        <li>
          You are responsible for your own actions and behaviour as well as for
          the actions and behaviours of any person (or persons) whom you bring
          with you to your Consultation.
        </li>
        <li>
          Seven Migration reserves the right to postpone and/or cancel any
          Consultation as a result of disruptive and/or inappropriate behaviour
          from you or anyone who attends your Consultation with you.
        </li>
        <li>
          Any form of abuse from you (or anyone accompanying you throughout your
          Consultation) directed at anyone at Seven Migration (including but not
          limited to its staff and/or other clients) will not be tolerated and
          will be subject to appropriate action.
        </li>
        <li>
          Both parties have an obligation to comply with the appropriate code of
          conduct and to preserve a friendly, respectful and professional
          environment. Please note that a copy of the official Code of Conduct
          for Registered Migration Agents will be made available at Seven
          Migration’s premises, should you need to refer to it at anytime prior,
          during or post your Appointment.{" "}
        </li>
        <li>
          In light of provisions set out under <strong>items 29 to 34</strong>,
          appropriate code of conduct extends to the use of Seven Migration’s
          equipment and premises. You are hereby responsible for the appropriate
          use of any property belonging to Seven Migration by you and/or any
          other person (or persons) accompanying you and any damage done to
          these assets may result in a fine to cover the replacement costs of
          such goods.
        </li>
        <li>
          There will be no tolerance for drug and/or alcohol use within Seven
          Migration’s premises.
        </li>
        <li>
          Throughout your Consultation, a Registered Migration Agent will ask
          you a series of questions relating but not limited to your family,
          educational and employment background, visa history in Australia and
          objectives in and outside Australia. You hereby agree to provide Seven
          Migration’s representatives with accurate and updated information at
          all times.
        </li>
        <li>
          Any migration plans, strategies and or advice given to you by Seven
          Migration’s Registered Migration Agents are solely based upon the
          information you have provided (or will provide) them with before,
          during or after your Consultation. As a result, you are responsible
          for ensuring the accuracy of such information.
        </li>
        <li>
          Should you fail to provide Seven Migration and/or its representatives
          with any information requested or should you provide wrong or
          inaccurate information, the migration plans, strategies and/or advices
          given to you by Seven Migration and it representatives may be
          compromised. As a result, you shall not be entitled to any form of
          compensation from Seven Migration for any losses and/or damage you
          endure as a result of any misrepresentation, misleading or deceptive
          conduct from your behalf or as a result of your failure to comply with{" "}
          <strong>items 37 and 38</strong>.
        </li>
        <li>
          Seven Migration offers no guarantee with respect to your compliance
          with eligibility requirements nor to the approval of visa applications
          and/or any other processes explained and/or recommended to you
          throughout your Consultation. Similarly, no reassurances are made in
          any of these regards.
        </li>
        <li>
          By allowing a third party to be present at a Consultation, for the
          sake of any items in these Terms and Conditions which make reference
          to such person (or persons), you hereby agree to be solely responsible
          for them and their actions/behaviour.
        </li>
        <li>
          Seven Migration shall not be, hence, held liable for any loss or
          damage you endure should any third party who attends your Consultation
          with you disclose and/or make any use of the
          information/advice/recommendation given to you by a Registered
          Migration Agent during such an Appointment. As a result, you also
          hereby agree to share any personal information discussed during
          Consultations with such third parties.
        </li>
        <li>
          As part of Seven Migrations fiduciary duties to act in your best
          interest and in accordance with the law, Seven Migration will keep all
          of your personal information private and will not disclose it to any
          unauthorised third parties.
        </li>
        <li>
          For the purposes of <strong>items 37 to 43</strong>, Consultations may
          relate to any services provided by Seven Migration, including but not
          limited to those in association with migration and/or skills
          assessment matters.
        </li>
        <li>
          Seven Migration may, on certain occasions, give clients entitlements
          to free-of-charge Consultations. Seven Migration does, however,
          reserve the right to alter its Booking Policies and to remove
          entitlement rights from any future bookings, without further notice.
          Please note, however, that any entitlements you obtain at the time of
          your booking (as per item 26) will be, nonetheless, honoured by Seven
          Migration; while any entitlements that are not be attached to your
          booking (either because they have been removed from Seven Migration’s
          Booking Policies or are no longer applicable to the circumstances of
          your booking) will not.
        </li>
        <li>
          You acknowledge that the above Terms and Conditions are fair,
          equitable and enforceable.
        </li>
        <li>
          Should, for any reason of law, any of the items/provisions set out
          under these Terms and Conditions be deemed invalid and/or void in a
          court of law, or be in contradiction to another item/provision, the
          remaining items/provisions shall be still enforceable upon both
          parties, in line with this agreement itself.{" "}
        </li>
        <li>
          These Terms and Conditions are in accordance with and governed by the
          Australian Law and all parties shall submit to the jurisdiction of the
          Australian Courts.
        </li>
        <li>
          Once agreed upon, these Terms and Conditions become a legally binding
          document that may be exercised in counterparts to ensure the
          enforcement of the provisions thereof. As such, any breaches to the
          above items shall be treated as a breach of contractual arrangement.
        </li>
        <li>
          You have fully read and understood the above Terms and Conditions and
          that you hereby irrevocably agree to abide by them for an indefinite
          period of time.{" "}
        </li>
      </ol>
    </Modal.Body>
  </Modal>
);

export default TermsAndConditions;
