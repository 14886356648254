import React from "react";

const PaidHeader = props => (
  <div className="header">
    <h3>Thank you for your booking!</h3>
    <p className="text-justify">You should receive an email with a final confirmation of your booking shortly.</p>
    <p className="text-justify">
      If you do not receive this email within 24 hours, please contact <a href="mailto:info@sevenmigration.com.au">info@sevenmigration.com.au</a>.
    </p>
  </div>
);

export default PaidHeader;
