import React from "react";
import StepWizard from "react-step-wizard";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import BookingStep1 from "./booking_step_1";
import BookingStep2 from "./booking_step_2";
import BookingStep3 from "./booking_step_3";

const Wizard = (props) => (
  <Container className="step-progress">
    <Card>
      <Card.Body>
        <StepWizard initialStep={1} isLazyMount>
          <BookingStep1 key="1" state={props.state} />
          <BookingStep2 key="2" state={props.state} />
          <BookingStep3 key="3" state={props.state} />
        </StepWizard>
      </Card.Body>
    </Card>
  </Container>
);

export default Wizard;
