import React from "react";

const MigrationAdviceDetails = props => (
  <div className="service-details">
    <h3 className="text-center">Migration Advice</h3>
    <p className="sub-header">
      Consultation Fee: <strong>{props.booking.price_formatted}</strong> (GST
      Inclusive)*
    </p>
    <h5>Services Included:</h5>
    <ul>
      <li>
        <p className="text-justify">
          Analysis of visa eligibility, according to clients’ personal
          circumstances and individual background and objectives.
        </p>
      </li>
      <li>
        <p className="text-justify">
          Provision of migration advice and development of an initial migration
          plan, tailored to clients’ personal circumstances and individual
          background and objectives.
        </p>
      </li>
      <li>
        <p className="text-justify">
          Provision of general orientation concerning professional validation
          processes (subject to individual analysis).
        </p>
      </li>
    </ul>
  </div>
);

export default MigrationAdviceDetails;
