import React from "react";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";

const AvailabilityRow = props => {
  const slots = props.slots.map(slot => (
    <Button
      variant="outline-primary custom"
      key={slot}
      value={`${props.day}-${slot}`}
      onClick={() => handleClick()}
      active={props.activeSlot == `${props.day}-${slot}`}
    >
      {slot}
    </Button>
  ));

  const handleClick = () => props.setActiveSlot(event.target.value);

  return (
    <Form.Group>
      <Form.Label id={props.day}>{props.day}</Form.Label>
      <br />
      <ButtonGroup aria-label="Slots available">{slots}</ButtonGroup>
    </Form.Group>
  );
};

export default AvailabilityRow;
