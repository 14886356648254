import { PayPalButton } from "react-paypal-button-v2";
import React from "react";

const PayNowButton = props => (
  <PayPalButton
    createOrder={(data, actions) => {
      return actions.order.create({
        purchase_units: [
          {
            custom_id: props.bookingId,
            description:
              "Thank you for your booking! You should receive an email with a final confirmation of your booking shortly after payment.",
            amount: {
              currency_code: props.currencyCode,
              value: props.bookingPrice
            }
          }
        ]
      });
    }}
    onApprove={(data, actions) => {
      return actions.order.capture().then(function(details) {
        alert("Your payment was confirmed. You should receive an email with your booking details.");
        location.reload();
      });
    }}
    onError={error => {
      console.log(error);
    }}
    options={{
      clientId: "AZHbMMBdUb6WVmzzHDQ_l54HAqqnnBY_aEsKIpCyKmtJ5tRSVmdY9RqEekoa2nsZ6yQn3mnTxp1KkKNf",
      currency: props.currencyCode,
      commit: true
    }}
    locale="en-AU"
    style={{
      layout: "horizontal",
      shape: "pill",
      color: "gold",
      label: "pay",
      tagline: false
    }}
  />
);

export default PayNowButton;
