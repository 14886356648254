import { parse } from "date-fns";
import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import AvailabilityRow from "./step_2/availability_row";
import Header from "./step_2/header";
import { fetchServiceTypes, fetchTimetable, saveBooking, saveClient } from "../actions/actions";
import Footer from "./step_2/footer";

const isWeekday = date => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

const buildVideoCallOptions = videoCallOptions => {
  let options = videoCallOptions.map((videoCallOption, index) => (
    <option value={videoCallOption} key={index}>
      {videoCallOption}
    </option>
  ));
  options.unshift(
    <option hidden value="" key={-1}>
      Select an Video Call Option:
    </option>
  );

  return options;
};

const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

const BookingStep2 = props => {
  const [agentsAvailable] = props.state("agents");
  const [availableSlots, setAvailableSlots] = props.state("availableSlots");
  const [activeSlot, setActiveSlot] = props.state("activeSlot");
  const [selectedOffice, setSelectedOffice] = props.state("selectedOffice");
  const [selectedAgent] = props.state("selectedAgent");
  const [loadingAvailableSlots] = props.state("loadingAvailableSlots");
  const [serviceTypes, setServiceTypes] = props.state("serviceTypes");
  const [client] = props.state("client");
  const [_booking, setBooking] = props.state("booking");
  const [selectedServiceType, setSelectedServiceType] = props.state("selectedServiceType");
  const [_loadingAvailableSlots, setLoadingAvailableSlots] = props.state("loadingAvailableSlots");
  const [videoCallOptions] = React.useState(["Skype", "Facebook", "Google Hangout"]);
  const [selectedVideoCall, setSelectedVideoCall] = React.useState("");
  const [videoCallId, setVideoCallId] = React.useState("");
  const [promoCode] = props.state("promoCode");

  React.useEffect(() => {
    scrollToTop();
    fetchServiceTypes().then(data => {
      setServiceTypes(data);
      setSelectedServiceType(data[0]);
    });
  }, []);

  React.useEffect(() => {
    // setSelectedOffice({});
    setAvailableSlots([]);
  }, [selectedAgent]);

  React.useEffect(() => {
    if (selectedAgent.id) {
      setActiveSlot("");
      if (selectedOffice.id) {
        fetchTimetableWithAnimation(selectedAgent, selectedOffice);
      }
    }
  }, [selectedServiceType, selectedOffice, selectedAgent]);

  const handleNextAvailable = () => {
    const [day, slots] = availableSlots[0];
    setActiveSlot(`${day}-${slots[0]}`);
    document.getElementById(day).scrollIntoView();
  };

  let activeCalendarDay = "";
  if (availableSlots.length > 0) {
    parse(availableSlots[0][0].split("-")[0], "dd/MM/yyyy", new Date());
  }

  if (activeSlot != "") {
    activeCalendarDay = parse(activeSlot.split("-")[0], "dd/MM/yyyy", new Date());
  }

  const availableSlotsContents = () => {
    if (availableSlots.length > 0) {
      return availableSlots.map(([day, slots]) => (
        <AvailabilityRow day={day} slots={slots} key={day} activeSlot={activeSlot} setActiveSlot={setActiveSlot} />
      ));
    }

    return (
      <small class="info-notice">
        <p>
          <strong>IMPORTANT NOTICE</strong>
        </p>
        <p>Dear Clients,</p>
        <p>
          Due to the uncertainty surrounding the COVID-19 crisis in Australia and in order to preserve the well-being of
          our stakeholders as this unfortunate situation continues to unfold, we have decided to suspend all in-person
          consultations and appointments with our team of Registered Migration Agents and Skills Assessment Specialists
          until further notice. Consultations and appointments are, however, still available through video conference to
          clients in and outside Australia.
        </p>
        <p>
          Thank you very much in advance for your time and kind understanding and we apologise in advance for any
          inconvenience caused.
        </p>
        <p>
          Please do not hesitate to contact us on{" "}
          <a href="mailto:info@sevenmigration.com.au">info@sevenmigration.com.au</a>, (08) 6167 7000 or (08) 7077 4777
          (Adelaide only) should you have any queries and/or concerns, and we look forward to meeting you!
        </p>
      </small>
    );
  };

  const fetchTimetableWithAnimation = (agent, office) => {
    setLoadingAvailableSlots(true);
    fetchTimetable(client, agent, office, selectedServiceType).then(data => {
      setAvailableSlots(data.timetable);
      setLoadingAvailableSlots(false);
    });
  };

  const servicesTypesContent = serviceTypes.map(serviceType => (
    <Button
      onClick={() => setSelectedServiceType(serviceType)}
      key={serviceType.id}
      variant={`outline-primary custom ${selectedServiceType.id === serviceType.id ? "active" : ""}`}
    >
      {serviceType.name}
    </Button>
  ));

  const maxDateCalendar = new Date().setDate(new Date().getDate() + 60);

  const minDateCalendar = new Date().setDate(new Date().getDate() + 1);

  const handleCalendarDateChange = date => {
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    let year = date.getFullYear().toString();

    if (day.length == 1) {
      day = "0" + day;
    }

    if (month.length == 1) {
      month = "0" + month;
    }

    const dayClicked = `${day}/${month}/${year}`;
    document.getElementById(dayClicked).scrollIntoView();
    const activeSlotClicked = availableSlots.find(([date, _slots]) => date == dayClicked);
    if (activeSlotClicked) {
      setActiveSlot(`${activeSlotClicked[0]}-${activeSlotClicked[1][0]}`);
    }
  };

  const buildBookingDetails = () => ({
    client_id: client.id,
    agent_id: selectedAgent.id,
    service_id: document.getElementById("service_id").value,
    office_id: selectedOffice.id,
    service_type_id: selectedServiceType.id,
    start_time: activeSlot,
    video_details: `${selectedVideoCall}: ${videoCallId}`
  });

  const handleNextClick = () => {
    const termsElement = document.getElementById("agree-terms-and-conditions");
    if (activeSlot === "") {
      alert("Please, select a slot available to continue.");
      event.preventDefault();
      event.stopPropagation();
    } else if (termsElement.reportValidity()) {
      saveClient(client);
      saveBooking(buildBookingDetails(), promoCode).then(data => {
        setBooking(data);
        props.nextStep();
      });
    }
  };

  const availableDates = () =>
    availableSlots.map(date => {
      const [dateDay, dateMonth, dateYear] = date[0].split("/");
      return new Date(dateYear, dateMonth - 1, dateDay);
    });

  const content = (
    <div className="step-2">
      <Form>
        <Header agents={agentsAvailable} setActiveSlot={setActiveSlot} state={props.state} />
        <Row>
          <Col lg="12">
            <small className="form-text text-muted text-right">
              All times are shown in {selectedOffice.timezone} timezone.
            </small>
          </Col>
        </Row>
        <Row>
          <Col lg="4" className="calendar">
            <DatePicker
              inline
              filterDate={isWeekday}
              selected={activeCalendarDay}
              minDate={minDateCalendar}
              maxDate={maxDateCalendar}
              includeDates={availableDates()}
              onChange={date => handleCalendarDateChange(date)}
            />
            <Button variant="outline-primary custom" onClick={() => handleNextAvailable()}>
              Show me Next Available
            </Button>
          </Col>
          <Col lg="8">
            <div className="availability-card">
              <Card>
                <Card.Title>
                  <ButtonGroup aria-label="Basic example">{servicesTypesContent}</ButtonGroup>
                  <hr />
                </Card.Title>
                <Card.Body>
                  {loadingAvailableSlots ? (
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    availableSlotsContents()
                  )}
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
        <Footer {...props} handleNextClick={handleNextClick} />
      </Form>
    </div>
  );

  return content;
};

export default BookingStep2;
