import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { fetchAgents, fetchNextBookingPrice, fetchOffices } from "../../actions/actions";
import { MIGRATION_MESSAGE, SKILLS_MESSAGE } from "../constants";
import AgentDropdown from "./agent_dropdown";
import CustomDropdown from "./custom_dropdown";

const Header = (props) => {
  const serviceId = document.getElementById("service_id").value;
  const isMigrationService = serviceId == "1";

  const [agents, setAgents] = React.useState([]);
  const [selectedAgent, setSelectedAgent] = props.state("selectedAgent");
  const [selectedOffice, setSelectedOffice] = props.state("selectedOffice");
  const [client] = props.state("client");
  const [booking, setBooking] = props.state("booking");
  const [offices, setOffices] = React.useState([]);

  React.useEffect(() => {
    fetchOffices(serviceId).then((data) => {
      setOffices(data);
    });

    if (!isMigrationService) {
      fetchAgents(serviceId).then((data) => {
        setAgents(data);
        setSelectedAgent(data[0]);
      });
    }

    fetchNextBookingPrice(client.id, serviceId).then((data) => setBooking({ ...booking, price: data.price }));
  }, []);

  const buildOfficeLocation = () => {
    return offices.map((office) => (
      <option value={office.id} key={office.id}>
        {office.name}
      </option>
    ));
  };

  const handleOfficeChange = (officeId) => {
    const office = offices.find((o) => o.id == officeId);
    setSelectedOffice(office);
    if (isMigrationService) {
      setAgents(office.agents);
      setSelectedAgent({});
    }
  };

  const handleAgentChange = (agent) => {
    setSelectedAgent(agent);
  };

  return (
    <>
      <h3>{isMigrationService ? "Migration Service" : "Brazil Consultation"}:</h3>
      <Row>
        <Col lg="12">
          <div className="service-message text-justify">{isMigrationService ? MIGRATION_MESSAGE : SKILLS_MESSAGE}</div>
          <div className="next-booking-price">
            Consultation fee for this booking: <strong>{booking.price}</strong> (GST Inclusive)*
          </div>
        </Col>
        <Col lg="12"></Col>
      </Row>
      <br />
      <Row>
        <Col lg="4">
          <CustomDropdown
            label="Office Location"
            options={buildOfficeLocation()}
            selectedOffice={selectedOffice}
            onChange={() => handleOfficeChange(event.target.value)}
          />
        </Col>
        {isMigrationService && (
          <Col lg="4">
            <AgentDropdown label="Agent" agents={agents} selectedAgent={selectedAgent} handleAgentChange={handleAgentChange} />
          </Col>
        )}
      </Row>
      <hr />
    </>
  );
};

export default Header;
