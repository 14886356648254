import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const BookingDetails = props => (
  <div className="booking-details">
    <h3 className="text-center">Booking Details</h3>
    <Row>
      <Col lg="3">Client:</Col>
      <Col lg="9">{props.booking.client.name}</Col>
    </Row>
    <Row>
      <Col lg="3">Email:</Col>
      <Col lg="9">{props.booking.client.email}</Col>
    </Row>
    <Row>
      <Col lg="3">Phone:</Col>
      <Col lg="9">{props.booking.client.phone}</Col>
    </Row>
    <Row>
      <Col lg="3">When:</Col>
      <Col lg="9">
        {props.booking.start_time_formatted_with_timezone} - {props.booking.service_type.name}
      </Col>
    </Row>
    {props.booking.service_type.id == 2 && (
      <Row>
        <Col lg="3">How:</Col>
        <Col lg="9">You will receive a Google Meet link at the time of your appointment.</Col>
      </Row>
    )}
    <Row>
      <Col lg="3">Agent:</Col>
      <Col lg="9">{props.booking.agent.display_name}</Col>
    </Row>
    <Row>
      <Col lg="3">Address:</Col>
      <Col lg="9">
        <a href={`https://maps.google.com/?q=${props.booking.office.address}`} target="_blank">
          {props.booking.office.address}
        </a>
      </Col>
    </Row>
  </div>
);

export default BookingDetails;
