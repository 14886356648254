import React from "react";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FlagIcon from "../FlagIcon";

const AgentItem = props => {
  return props.agents.map(agent => (
    <Dropdown.Item href="#" onClick={() => props.itemClick(agent)} key={agent.id}>
      <NameWithFlags agentName={agent.display_name} languages={agent.languages} />
    </Dropdown.Item>
  ));
};

const NameWithFlags = props => {
  const languages = props.languages.sort((a, b) => a.id - b.id);

  return (
    <Row className="agent-container">
      <Col lg={7}>
        <span className="agent-name">{props.agentName}</span>
      </Col>
      <Col lg={5}>
        {languages.map(language => (
          <FlagIcon className="agent-flag" code={language.flag_code} size="lg" key={language.id} />
        ))}
      </Col>
    </Row>
  );
};

const AgentDropdown = props => {
  return (
    <Form.Group controlId={props.Label}>
      <Form.Label>{props.label}:</Form.Label>
      <Dropdown className="custom-dropdown">
        <Dropdown.Toggle variant="success">
          {props.selectedAgent.id ? (
            <NameWithFlags agentName={props.selectedAgent.display_name} languages={props.selectedAgent.languages} />
          ) : (
            <Dropdown.Item disabled>Click here to select an agent...</Dropdown.Item>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <AgentItem agents={props.agents} onClick={() => event.preventDefault()} itemClick={props.handleAgentChange} />
        </Dropdown.Menu>
      </Dropdown>
      <small class="agents-link">
        <p>Don't know which Migration Agent to book with?</p>
        <p>
          Click{" "}
          <a href="http://www.sevenmigration.com.au/about-us/" target="_blank">
            <strong>here</strong>
          </a>{" "}
          to get to know a little bit of each one.
        </p>
      </small>
    </Form.Group>
  );
};

export default AgentDropdown;
