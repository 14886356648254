import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import PayNowButton from "./pay_now_button";

const Footer = props => {
  return (
    <>
      <Row className="footer">
        <Row>
          <Col lg="12">
            <p className="text-justify">
              Please note that this Consultation Fee does not, however, include fees related but not limited to: (i)
              visa application charges; (ii) legal representation fees (e.g., migration agent costs); (iii) any other
              fees/charges/disbursements applicable throughout the process of lodging a visa application and/or
              appealing to a decision; or (iv) the lodgement of skills assessment applications and their subsequent
              processes.
            </p>
            <br />
          </Col>
        </Row>
        <Row>
          <Col style={{ display: "flex", alignItems: "flex-end" }}>
            <Button variant="outline-primary custom back" onClick={props.previousStep}>
              Back
            </Button>
            {props.showPayPalButton && <PayNowButton bookingId={props.bookingId} bookingPrice={props.bookingPrice} currencyCode={props.currencyCode} />}
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default Footer;
