import React from "react";

const SkillsAssessmentDetails = props => (
  <div className="service-details">
    <h3 className="text-center">Brazil Consultation</h3>
    <p className="sub-header">
      Consultation Fee: <strong>{props.booking.price_formatted}</strong> (GST
      Inclusive)*
    </p>
    {/* <h5>Services Included:</h5>
    <ul>
      <li>
        <p className="text-justify">
          Pre-assessment of qualifications and skills to determine their
          equivalence to an occupation in Australia (as well as their level
          equivalence in comparison to the AQF Framework, whenever available).
        </p>
      </li>
      <li>
        <p className="text-justify">
          Comprehensive advice for skills assessment processes with VETASSESS,
          ACS and TRA (as well as preliminary research for processes conducted
          by other authorities, on a case-by-case basis).
        </p>
      </li>
      <li>
        <p className="text-justify">
          Recommendation of steps to be taken in order to comply with the
          requirements established the above assessment authorities.
        </p>
      </li>
    </ul> */}
  </div>
);

export default SkillsAssessmentDetails;
