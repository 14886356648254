import React from "react";

export const MIGRATION_MESSAGE = (
  <small>
    This service relates to the analysis of clients’ eligibility to Australian
    visas <strong>ONLY</strong>. Throughout Migration Advice consultations, our
    Registered Migration Agents will analyse your individual circumstances in
    order to provide you with a comprehensive migration plan.
  </small>
);

export const SKILLS_MESSAGE = (
  <small>

  </small>
);
